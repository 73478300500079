/*
    SALES REPRESENTATIVE STYLE
*/
/*========global=======*/
/* screen sizes */
.rounded-5 {
  border-radius: 10px;
}

h2 {
  font-family: "Bebas Neue", cursive;
  font-size: 2.5rem;
}

h3 {
  font-family: "Bebas Neue", cursive;
  font-size: 2rem;
}

.primary-colour {
  color: #ff6600;
}

.secondary-colour {
  background: #000000;
}

.primaryBg {
  background: #ff6600;
}

.whiteText {
  color: #ffffff;
}

/*===end=====global=======*/
body {
  font-family: "Poppins", sans-serif;
  background: #e3e3e3;
  font-size: 16px;
  color: #000000;
}

.darkBg {
  background-color: #333333;
}

.darkBg nav.navbar {
  display: none;
}

.darkBg .App:before {
  content: "";
  position: absolute;
  background-color: #ff6600;
  width: 33%;
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
}

.darkBg .App:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 360px;
  height: 280px;
  background: url("/public/images/logo-transparent.png") no-repeat center top;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#root {
  border-left: 10px solid #ff6600;
}

.navbar .navbar-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none !important;
  border: none;
}

.navbar .logo {
  background: #ff6600;
}

.navbar .nav-item .nav-link {
  color: #000000;
  font-weight: 500;
  padding: 0 20px;
  font-size: 18px;
}

.navbar .nav-item .nav-link.active {
  color: #ff6600;
}

.navbar .nav-item .nav-link:hover {
  color: #ff6600;
}

.sidebarBlock {
  height: 100%;
}

.sidebarBlock ul.sidebarBtn {
  padding-left: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  gap: 15px;
}

.sidebarBlock ul.sidebarBtn li a {
  font-weight: 600;
  height: 185px;
  background-color: #ffffff;
  letter-spacing: 1px;
  cursor: auto;
}

.sidebarBlock ul.sidebarBtn li a.active {
  background-color: #ff6600;
  color: #ffffff;
}

.frontForm {
  max-width: 450px;
  margin: auto;
}

input[type="text"] {
  background: #fff5ee;
}

.loginForm .form-control {
  background: #fff5ee;
}

.form-control {
  background-color: #fff5ee;
  border: 1px solid #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-select-sm {
  width: 160px;
}

.multiselect-container {
  background-color: #fff5ee;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.multiselect-container .search-wrapper {
  border: none;
  padding: 0;
}

.multiselect-container .search-wrapper input {
  padding: 0 0 0 5px;
  margin: 0;
}

.multiselect-container .highlightOption {
  background: none !important;
  color: #000000;
}

.form-select-sm {
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-control:focus {
  background-color: #fff5ee;
  border-color: #f89b5c;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
}

.fieldIcon svg {
  position: absolute;
  left: 15px;
  top: 13px;
}

.fieldIcon input.form-control {
  padding-left: 45px;
}

.btn {
  padding: 10px 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

.btn-sm {
  padding: 10px 20px;
}

#form > input,
.btn {
  border: 1px solid #f89b5c;
}

.btn-primary {
  background-color: #ff6600;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #000000;
  border-color: transparent;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  background-color: #000000;
}

.form-check-input {
  border-color: #000000;
  width: 1.3em;
  height: 1.3em;
}

.form-check-input:focus {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
}

.form-check-input:checked {
  background-color: #ff6600;
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
}

.loginCheckbox label {
  margin-left: 10px;
  margin-top: 6px;
}

.table.upper tr td {
  text-transform: uppercase;
}

table tr th {
  background-color: #ff6600 !important;
  color: #ffffff;
  font-weight: 500;
  vertical-align: top;
}

.invalid-feedback {
  display: block;
  text-align: left;
}

.form-check-input[type="checkbox"] {
  float: none;
}

.carImage img {
  max-width: 463px;
  width: 100%;
}

.sidebarLeft:before {
  content: "";
  background-color: #f5f5f5;
  height: 100%;
  width: 520px;
  position: absolute;
  left: 0;
  z-index: 0;
}

.sidebarLeft .sidebarLeftInner {
  height: 100%;
  padding: 100px 0;
  min-height: 560px;
}

.sidebarLeft .sidebarLeftInner .logo-login {
  margin-top: -100px;
}

.carImage {
  position: absolute;
  bottom: 0;
  right: -30px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

/*============= nav-bar ===============*/
.navbar-nav li a {
  padding: 0 10px;
  position: relative;
}

.navbar-nav li a:hover {
  color: #ff6600;
}

@media (max-width: 767px) {
  .navbar-nav li a {
    padding: 0 8px;
  }
}

.navbar-nav li.active a {
  color: #ff6600;
}

.navbar-nav .navNotification span {
  position: absolute;
  right: 0;
  top: 0;
  background: #ff6600;
  color: #fff;
  padding: 0 5px;
  border-radius: 35px;
  width: 25px;
  height: 25px;
  text-align: center;
  top: -8px;
  line-height: 25px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .navbar-nav .navNotification span {
    line-height: 22px;
    font-size: 12px;
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 767px) {
  .navbar-nav li.navUsername {
    display: none;
  }
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  padding: 0px;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.scale-small {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (min-width: 992px) and (max-width: 1199px) {
  .sidebarLeft::before {
    width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebarLeft::before {
    width: 300px;
  }
  .darkBg .App::after {
    background-size: 200px;
    width: 252px;
    height: 180px;
  }
}

@media screen and (max-device-width: 991px) and (orientation: landscape) {
  .loginBlock {
    height: auto !important;
  }
}

@media (max-width: 991px) {
  #root {
    border-left: 0;
  }
  body {
    font-size: 14px;
  }
  .sidebarBlock ul.sidebarBtn {
    gap: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 991px) and (max-width: 767px) {
  .sidebarBlock ul.sidebarBtn li a {
    height: 145px;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .userProfile {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .userProfile ul.navbar-nav {
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
        -ms-flex-direction: inherit;
            flex-direction: inherit;
    margin: 0 !important;
  }
  .sidebarLeft .sidebarLeftInner {
    min-height: 560px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 80%;
  }
  .logo img {
    width: 150px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  .sidebarLeft::before {
    display: none;
    content: inherit;
  }
  .darkBg .App::after {
    background: none;
  }
  .sidebarLeft .sidebarLeftInner {
    min-height: inherit;
    padding: 20px 0;
  }
  .sidebarLeft .sidebarLeftInner .logo-login {
    margin-top: 0;
  }
  .sidebarLeft .sidebarLeftInner .carImage {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .sidebarLeft .sidebarLeftInner .carImage img {
    max-width: 200px;
  }
}

a.nav-link {
  color: #fff;
}

tbody {
  text-align: left;
}

.quote-textarea {
  height: 280px;
}

.staff-quote-textarea {
  height: 387px;
}

pre {
  font-family: "Poppins", cursive;
  font-size: 1rem;
  margin: 0;
  white-space: pre-line;
}

.pdfIcon {
  color: red;
}

.form-control {
  font-family: "Poppins", sans-serif;
}

.form-control option {
  font-family: "Poppins", sans-serif;
}

@page {
  size: auto;
  margin: 3mm 10mm 3mm 10mm;
}

#react-confirm-alert .react-confirm-alert-overlay {
  z-index: 1111;
}

#react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body {
  background: #000000;
  text-align: center;
}

@media (max-width: 420px) {
  #react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body {
    width: 320px;
  }
}

#react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body h1 {
  font-family: "Bebas Neue", cursive;
  color: #ff6600;
}

#react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body .react-confirm-alert-button-group {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#react-confirm-alert .react-confirm-alert-overlay .react-confirm-alert-body .react-confirm-alert-button-group button {
  background-color: #ff6600;
  letter-spacing: 1px;
  padding: 10px 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
}
