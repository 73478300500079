/*
    SALES REPRESENTATIVE STYLE
*/
/*========global=======*/

// Colours
$primary-colour: #ff6600;
$secondary-colour: #000000;
$accent-colour: #333333;
$white-colour: #ffffff;
$black-colour: #000000;

$copy-colour: #fff5ee;
$copy-light: #9aa1aa;

$light-grey: #f5f5f5;
$grey: #e7e7e7;
$white: #ffffff;

$border-primary: #ff6600;
$border-secondary: #333333;

$weight-medium: 500;
$weight-semi: 600;

// Font
$primary-font: "Poppins", sans-serif;
$secondary-font: "Bebas Neue", cursive;

$copy-size: 16px;
$xxsmall-copy-size: 11px;
$xsmall-copy-size: 12px;
$small-copy-size: 14px;
$medium-copy-size: 16px;
$large-copy-size: 18px;
$xlarge-copy-size: 21px;

$heading-copy-size: 1.5rem;
$large-heading-copy-size: 2rem;
$xlarge-heading-copy-size: 2.5rem;

$container-width: 1440px;
$small-container-width: 1080px;

$small-spacing: 10px;
$standard-spacing: 15px;
$large-spacing: 20px;
$xlarge-spacing: 40px;

/* screen sizes */
$standard-laptop: 1400px;
$large-tablet: 1199px;
$tablet: 991px;
$mobile: 767px;
$small-mobile: 480px;

.rounded-5 {
  border-radius: 10px;
}

h2 {
  font-family: $secondary-font;
  font-size: $xlarge-heading-copy-size;
}
h3 {
  font-family: $secondary-font;
  font-size: $large-heading-copy-size;
}
.primary-colour {
  color: $primary-colour;
}

.secondary-colour{
  background:$secondary-colour;
}

.primaryBg{
  background:$primary-colour;
}

.whiteText{
  color:$white
}

/*===end=====global=======*/

body {
  font-family: "Poppins", sans-serif;
  background: #e3e3e3;
  font-size: $medium-copy-size;
  color: $black-colour;
}

.darkBg {
  background-color: $accent-colour;
  nav.navbar {
    display: none;
  }
  .App {
    &:before {
      content: "";
      position: absolute;
      background-color: $primary-colour;
      width: 33%;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 360px;
      height: 280px;
      background: url("/public/images/logo-transparent.png") no-repeat center
        top;
    }
  }
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#root {
  border-left: 10px solid $primary-colour;
}

.navbar {
  .navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
  }

  .logo {
    background: $primary-colour;
  }

  .nav-item {
    .nav-link {
      color: $black-colour;
      font-weight: $weight-medium;
      padding: 0 $large-spacing;
      font-size: $large-copy-size;

      &.active {
        color: $primary-colour;
      }

      &:hover {
        color: $primary-colour;
      }
    }
  }
}

.sidebarBlock {
  height: 100%;

  ul.sidebarBtn {
    padding-left: 0;
    list-style: none;
    display: grid;
    gap: 15px;

    li {
      a {
        font-weight: $weight-semi;
        height: 185px;
        background-color: $white-colour;
        letter-spacing: 1px;
        cursor: auto; 

        &.active {
          background-color: $primary-colour;
          color: $white-colour;
        }

      }
    }
  }
}

.frontForm {
  max-width: 450px;
  margin: auto;
}

input[type="text"] {
  background: $copy-colour;
}

.loginForm{
  .form-control {
    background: $copy-colour;
  }
}

.form-control {
  background-color: $copy-colour;
  border: 1px solid $secondary-colour;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-select-sm{
  width:160px;
}

.multiselect-container {
  background-color: $copy-colour;
  border: 1px solid $secondary-colour;
  border-radius: 0.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;

  .search-wrapper {
    border: none;
    padding: 0;

    input {
      padding: 0 0 0 5px;
      margin: 0;
    }
  }

  .highlightOption{
    background: none !important;
    color: #000000;
  }
}

.form-select-sm {
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-control:focus {
  background-color: $copy-colour;
  border-color: #f89b5c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
}

.fieldIcon {
  svg {
    position: absolute;
    left: 15px;
    top: 13px;
  }
  input.form-control {
    padding-left: 45px;
  }
}

.btn {
  padding: $small-spacing $large-spacing;
  font-weight: $weight-medium;
  letter-spacing: 1px;
}

.btn-sm {
  padding: 10px 20px;
}

#form > input,
.btn {
  border: 1px solid #f89b5c;
}

.btn-primary {
  background-color: $primary-colour;
  color: $white-colour;

  &:hover {
    background-color: $black-colour;
    border-color: transparent;
  }
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  background-color: $black-colour;
}

.form-check-input {
  border-color: $secondary-colour;
  width: 1.3em;
  height: 1.3em;

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  }

  &:checked {
    background-color: $primary-colour;
    border-color: transparent;
    box-shadow: 0 0 0 0.25rem rgba(255, 102, 0, 0.25);
  }
}

.loginCheckbox{
  label{
    margin-left: 10px;
    margin-top: 6px;
  }
}
.table.upper tr td {
	text-transform: uppercase;
}

table {
  tr {
    th {
      background-color: $primary-colour !important;
      color: $white-colour;
      font-weight: $weight-medium;
      vertical-align: top;
    }
  }
}

.invalid-feedback {
  display: block;
  text-align: left;
}

.form-check-input[type="checkbox"] {
  float: none;
}

.carImage img {
  max-width: 463px;
  width: 100%;
}

.sidebarLeft {
  &:before {
    content: "";
    background-color: $light-grey;
    height: 100%;
    width: 520px;
    position: absolute;
    left: 0;
    z-index: 0;
  }
  .sidebarLeftInner {
    height: 100%;
    padding: 100px 0;
    min-height: 560px;

    .logo-login {
      margin-top: -100px;
    }
  }
}

.carImage {
  position: absolute;
  bottom: 0;
  right: -30px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

/*============= nav-bar ===============*/

.navbar-nav{
  li{
    a{
      padding:0 10px;
      position:relative;
      
      &:hover{
        color:$primary-colour;
      }

      @media(max-width:767px){
        padding: 0 8px;
      }

    }
  }
  li.active{
    a{
      color:$primary-colour;
    }
  }
  .navNotification{
    span{
      position: absolute;
      right: 0;
      top: 0;
      background: $primary-colour;
      color: #fff;
      padding: 0 5px;
      border-radius: 35px;
      width: 25px;
      height: 25px;
      text-align: center;
      top: -8px;
      line-height: 25px;
      font-size: 14px;

      @media(max-width:767px){
        line-height: 22px;
        font-size: 12px;
        width: 22px;
        height: 22px;
      }
    }
  }
  @media(max-width:767px){
    li.navUsername{
      display:none
    }
  }
}



/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 0px;
  min-height: 100vh;
  transition: all 0.3s;
}

.scale-small {
  transform: scale(0.5);
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
// @media print {
//   .quoteTable table.table-responsive thead.container tr:last-child{ overflow: hidden; height: 0;display: none; }
// }


@media (min-width: 992px) and (max-width: 1199px) {
  .sidebarLeft::before{
    width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebarLeft::before{
    width: 300px;
  }

  .darkBg{
    .App::after{
      background-size: 200px;
      width: 252px;
      height: 180px;
    }
  }

}

@media screen and (max-device-width: 991px) and (orientation: landscape) {
  .loginBlock{
    height:auto !important
  }
}

@media (max-width: 991px) {

  #root {
    border-left: 0;
  }

  body{
    font-size: 14px;
  }

  .sidebarBlock{
    ul.sidebarBtn{
      gap: 0;
      display: flex;

      @media (max-width: 767px) {
        li{
          a{
            height: 145px;
          }
        }
      }

    }    
  }

  #navbarSupportedContent{
    order: 2;
  }
  
  .userProfile{
    order: 1;
  
    ul.navbar-nav{
      flex-direction: inherit;
      margin: 0 !important;
    }
  }
  
  .sidebarLeft{
    .sidebarLeftInner{
      min-height: 560px;
    }
  }

  
}

@media (max-width: 767px) {

  html{
    font-size:80%
  }

  .logo{
    img{
      width: 150px;
    }
  }
  
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }

  .sidebarLeft::before{
    display:none;
    content:inherit;
  }

  .darkBg{
    .App::after{
      background:none;
    }
  }

  .sidebarLeft{
    .sidebarLeftInner{
      min-height: inherit;
      padding: $large-spacing 0;
      .logo-login{
        margin-top: 0;
      }
      .carImage{
        position: relative;
        bottom: 0;
        right: 0;

        img{
          max-width: 200px;
        }
      }
    }
  }


}

a.nav-link {
  color: #fff;
}
tbody {
  text-align: left;
}

.quote-textarea{
  height: 280px;
}

.staff-quote-textarea{
  height: 387px; 
}

pre{
  font-family: "Poppins", cursive;
  font-size: 1rem;
  margin:0;
  white-space: pre-line;
}

.pdfIcon{
  color:red;
}

.form-control{
  font-family: $primary-font;
}  

.form-control option{
  font-family: $primary-font;
}

@page {
  size: auto;
  margin: 3mm 10mm 3mm 10mm; 
}

#react-confirm-alert{
  .react-confirm-alert-overlay{
    z-index: 1111;

    .react-confirm-alert-body{
      background: $black-colour;
      text-align: center;

      @media(max-width:420px){
        width: 320px;
      }
      
      h1{
        font-family:$secondary-font;
        color:$primary-colour
      }

      .react-confirm-alert-button-group{
        justify-content: center;

        button{
          background-color: $primary-colour;
          letter-spacing: 1px;
          padding: 10px 20px;
          font-weight: 500;
          font-family:$primary-font;
          text-transform:uppercase;
          font-size: 1rem;
        }
      }
    }
  }
}