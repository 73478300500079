@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* > * {
  font-family: "Poppins", cursive;
  margin: 0;
}

body {
  background: #615d81; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #9e9eb1,
    #8e8ab1,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 860px) {
  .register .col-2 img {
    display: initial;
  }
}
/* Forget Password Style */
.form-gap {
  padding-top: 70px;
}
.multiSelectContainer li:hover {
  background: #ff6600 !important;
  color: #fff;
  cursor: pointer;
}

.highlightOption {
  background: #ff6600 !important;
  color: #fff;
}
.chip {
  padding: 4px 10px;
  background: #ff6600 !important;
}
/* Change Password Style */

@media print{
.attach{
display:none;
}
}
/* .table.upper tr td {
	text-transform: uppercase;
} */
td.col-md-6.mb-3.upper {
    text-transform: uppercase;
}